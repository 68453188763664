<script>
import { mapStores, mapWritableState } from 'pinia'
import { useOrgStore, useOptionsStore } from '@/stores'
import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill'

import utils from '@/utils.js'

export default {
  name: 'ScanTag',

  components: {},

  props: {
    message: { type: String, default: null },

    login: { type: Boolean, default: false },
    // allow login token scans; otherwise we only return valid platform url results
  },

  emits: ['change', 'scan'],

  data: () => ({
    barcodeDetector: null,
    devices: [],
    isDebug: import.meta.env.VITE_DEBUG === 'true',
    isMirrored: false,
    selectedDevice: null,
    showUI: false,
    stream: null,
    videoSrc: null,
  }),

  computed: {
    ...mapWritableState(useOptionsStore, ['deviceOptions']),
    ...mapStores(useOrgStore),
  },

  watch: {
    selectedDevice(val) {
      this.updateStream(val)
      this.$emit('change', val)
    },
  },

  created() {
    this.initializeBarcodeDetector()
  },

  async mounted() {
    await this.reload()
    this.selectedDevice = this.deviceOptions?.selectedDevice
  },

  beforeUnmount() {
    this.turnOff()
  },

  methods: {
    initializeBarcodeDetector() {
      try {
        window['BarcodeDetector'].getSupportedFormats()
      } catch {
        window['BarcodeDetector'] = BarcodeDetectorPolyfill
      }

      this.barcodeDetector = new window.BarcodeDetector({
        formats: ['qr_code'],
      })
    },

    async scan() {
      let barcodes = await this.barcodeDetector
        .detect(this.$refs.preview)
        .catch((err) => console.debug('BarcodeDetector error:', err))
      barcodes?.forEach(this.onDecode)

      if (this.videoSrc) {
        setTimeout(this.scan, 2000)
      }
    },

    onPlay() {
      this.showUI = true
      this.scan()
    },

    onDecode(barcode) {
      console.debug('onDecode', barcode)
      let code = barcode?.rawValue || barcode

      if (this.login || utils.isLiffHost(code)) {
        this.$emit('scan', code)
      }
    },

    async turnOff() {
      await this.stream?.getVideoTracks().forEach((track) => track.stop())

      if (this.videoSrc) {
        this.videoSrc = null
        setTimeout(this.scan, 2000)
      }
    },

    async updateStream(deviceId) {
      console.debug(`updateStream(${deviceId})`)
      await this.turnOff()

      const constraints = {
        video: {
          deviceId,
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      }

      let mediaStream = await window.navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((error) => console.error(error))
      this.stream = mediaStream
      this.videoSrc = mediaStream
    },

    async reload() {
      try {
        let deviceInfos = await window.navigator.mediaDevices.enumerateDevices()
        this.devices = deviceInfos.filter((info) => info.kind === 'videoinput')
        console.debug(
          'Available devices:',
          this.devices.map((d) => d.deviceId)
        )
        this.selectedDevice =
          this.deviceOptions?.selectedDevice || this.devices[0]?.deviceId
      } catch (error) {
        console.error('Error enumerating devices:', error)
      }
    },

    nextDevice() {
      let currentIndex = this.devices.findIndex(
        (d) => d.deviceId === this.selectedDevice
      )
      let nextIndex = (currentIndex + 1) % this.devices.length
      this.selectedDevice = this.devices[nextIndex]?.deviceId
      console.debug('Selected device:', this.selectedDevice)
      this.deviceOptions = Object.assign(this.deviceOptions, {
        selectedDevice: this.selectedDevice,
      })
    },

    toggleMirror() {
      this.isMirrored = !this.isMirrored
    },
  },
}
</script>

<template>
  <main class="card has-text-centered scantag-modal">
    <div class="scantag-wrapper">
      <div class="scantag-overlay">
        <slot> </slot>
      </div>

      <form class="scantag-overlay">
        <template v-if="showUI">
          <div class="scantag-btns">
            <o-button
              variant="text"
              size="medium"
              icon-left="camera-flip"
              class="capture-btn"
              style="color: white"
              @click.prevent="nextDevice" />

            <p v-if="message">{{ message }}</p>

            <o-button
              variant="text"
              size="medium"
              :icon-left="
                isMirrored ? 'camera-document' : 'camera-document-off'
              "
              class="capture-btn"
              style="color: white"
              @click.prevent="toggleMirror" />
          </div>
        </template>

        <template v-else>
          <div class="container loading-container">
            <o-loading :full-page="false" active />
          </div>
        </template>
      </form>

      <video
        id="preview"
        ref="preview"
        class="scantag-camera"
        :class="{ 'mirror-video': isMirrored }"
        :srcObject.prop="videoSrc"
        autoplay
        muted
        playsinline
        @play="onPlay" />
    </div>
  </main>
</template>

<style scoped lang="scss">
.scantag-camera {
  width: 100%;
  height: 100%;
  background-color: white;
}

.scantag-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.scantag-wrapper {
  position: relative;
  display: inline-block;
}

.scantag-btns {
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  bottom: 0.5rem;
  width: 100%;
  z-index: 10;
}

.scantag-btns button {
  margin: auto 0;
}

.mirror-video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.loading-container {
  position: relative;
  width: 100%;
  height: 20rem;
}
</style>

<style lang="scss">
.scantag-modal {
  min-height: 20rem;
  @media (max-width: 768px) {
    min-height: 10rem;
  }
}
</style>
